.main {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: #000000;
}
.logo {
  height: 70vh;
  width: 70vh;
  margin: auto;
}
